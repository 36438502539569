<template>
  <base-layout :admin="true">
    <router-view />
  </base-layout>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout.vue'
export default {
  components: { BaseLayout }
}
</script>
